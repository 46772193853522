import React, { useEffect } from 'react'
import { Form, Button } from 'components'
import { useSetupTabValueContext, useSetupTabActionsContext } from './SetupTabContext'
import { Row, Col } from 'react-bootstrap'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

const SetupTab = (props) => {
    const { form, dirty, adding } = useSetupTabValueContext();
    const { onFormChange, onInit, onSubmit, onCancel } = useSetupTabActionsContext();

    useEffect(() => {
        onInit(props.configuration);
    }, [onInit, props.configuration]);

    const renderHeader = () => {
        if (!form.title)
            return;

        return (
            <div>
                <h4>
                    {form.title}
                </h4>
                <hr />
            </div>
        )
    }

    const renderFooter = () => {
        return (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                {!adding &&
                    <Button
                        variant="link"
                        style={{ marginRight: "10px" }}
                        onClick={onCancel}>
                        Cancelar
                    </Button>}
                <Button
                    type="submit"
                    disabled={!dirty}>
                    Guardar
                </Button>
            </div>
        )
    }

    const renderActions = () => {
        if (!props.actions)
            return;

        return (
            <div style={{ float: "right", display: "flex", flexDirection: "column" }}>
                {props.actions.map(x => {
                    const icon = x.key === "delete" ? faTrash : x.icon;

                    return (
                        <Button
                            key={x.key}
                            onClick={x.onClick}
                            icon={icon}
                            iconPosition="left"
                            variant="success"
                            style={{ height: "28px", fontSize: "14px", lineHeight: "0px", marginBottom: "5px", whiteSpace: "nowrap" }}>
                            {x.name}
                        </Button>
                    )
                })}
            </div>
        )
    }

    return (
        <div style={{ backgroundColor: "white", padding: "20px 10px 10px 15px" }}>
            <Form
                value={form}
                onChange={onFormChange}
                onSubmit={onSubmit}>
                <Row>
                    {props.renderHeader ? props.renderHeader() : renderHeader()}
                </Row>
                <Row>
                    <Col md={props.actions ? 11 : 12}>
                        {props.children}
                    </Col>
                    <Col md={1}>
                        {!adding && renderActions()}
                    </Col>
                </Row>
                {props.displaySave &&
                    <React.Fragment>
                        <hr />
                        <Row>
                            {renderFooter()}
                        </Row>
                    </React.Fragment>}
            </Form>
        </div>
    )
}

export default SetupTab;

SetupTab.defaultProps = {
    displaySave: true
}