import React from 'react'
import Search from './Search'
import SetupPage from 'modules/setup/SetupPage'
import ConsultaWebInfoTab from './consultaWebInfo/ConsultaWebInfoTab'
import { faSquare } from '@fortawesome/free-solid-svg-icons'

const ConsultaWebSetup = () => {

	const setupProps = {
		title: "Consultas Web",
		icon: faSquare,
		searchUrl: "/consultaWeb/search",
		rowKey: "idConsultaWeb",
		columns: [
			{ key: "idConsultaWeb", name: "ID", sortable: true, width: 60 },
			{ key: "nombreyapellido", name: "Nombre y Apellido", sortable: true },
			{ key: "correo", name: "Correo", sortable: true },
			{ key: "consulta", name: "Consulta", sortable: true }
		],
		tabs: [
			{ key: "info", title: "Info", comp: ConsultaWebInfoTab }
		]
	};

	return (
		<SetupPage
			setupProps={setupProps}
			search={Search} />
	)
}

export default ConsultaWebSetup;