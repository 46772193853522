import React, { useMemo, useRef } from 'react'
import SetupTab from 'modules/setup/setupTab/SetupTab'
import { Label, RemoveModal } from 'components'
import { Row, Col, FormGroup } from 'react-bootstrap'
import { fetchUrl } from 'modules/util/functions'
import { useSetupTabValueContext, useSetupTabActionsContext } from 'modules/setup/setupTab/SetupTabContext'

const ConsultaWebInfoTab = () => {
	const { selectedId, form } = useSetupTabValueContext();
	const { onDelete, onError, onSuccess } = useSetupTabActionsContext();
	const _removeModal = useRef();

	const configuration = useMemo(() => ({
		urls: {
			get: "/consultaWeb/getConsultaWebInfo"
		},
		getRequest: () => {
			return {
				idConsultaWeb: selectedId
			}
		}
	}), [selectedId]);

	const actions = [{
		key: "delete",
		name: "Borrar",
		onClick: () => {
			handleDelete();
		}
	}];

	// Handlers
	const handleDelete = () => {
		const rq = {
			idConsultaWeb: selectedId
		};

		_removeModal.current.open({
			title: "Eliminando consulta web",
			name: form.nombre,
			onYes: () => {
				fetchUrl("/consultaWeb/deleteConsultaWeb", "post", rq, {
					success: (r) => {
						onSuccess(r.message);
						onDelete();
					},
					error: (r) => {
						onError(r.errorMessage);
					}
				}, { block: false });
			}
		});
	}

	return (
		<React.Fragment>
			<RemoveModal
				ref={_removeModal} />

			<SetupTab
				configuration={configuration}
				actions={actions}
				displaySave={false}>
				<Row>
					<Col md={4}>
						<FormGroup>
							<Label>Nombre y Apellido</Label>
							<div>{form.nombreyapellido}</div>
						</FormGroup>
						<FormGroup style={{ marginTop: "20px" }}>
							<Label>Telefono</Label>
							<div>{form.telefono}</div>
						</FormGroup>
						<FormGroup style={{ marginTop: "20px" }}>
							<Label>Correo</Label>
							<div>{form.correo}</div>
						</FormGroup>
						<FormGroup style={{ marginTop: "20px" }}>
							<Label>Consulta</Label>
							<div>{form.consulta}</div>
						</FormGroup>
					</Col>
				</Row>
			</SetupTab>
		</React.Fragment>
	)
}

export default ConsultaWebInfoTab;