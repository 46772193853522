import React from 'react'
import SetupSearch from 'modules/setup/setupSearch/SetupSearch'
import { SearchBox } from 'components'
import { Row, Col } from 'react-bootstrap'

const Search = () => {

	return (
		<SetupSearch>
			<Row>
				<Col md={3}>
					<SearchBox
						name="searchText"
						placeholder="Nombre, correo..." />
				</Col>
			</Row>
		</SetupSearch>
	)
}

export default Search;