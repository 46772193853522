import React, { useMemo, useRef } from 'react'
import SetupTab from 'modules/setup/setupTab/SetupTab'
import { TextBox, Label, RemoveModal } from 'components'
import { Row, Col, FormGroup } from 'react-bootstrap'
import { fetchUrl } from 'modules/util/functions'
import { useSetupTabValueContext, useSetupTabActionsContext } from 'modules/setup/setupTab/SetupTabContext'

const ContactoInfoTab = () => {
    const { selectedId, form } = useSetupTabValueContext();
    const { onDelete, onError, onSuccess } = useSetupTabActionsContext();
    const _removeModal = useRef();

    const configuration = useMemo(() => ({
        urls: {
            get: "/contacto/getContactoInfo",
            save: "/contacto/saveContactoInfo",
            create: "/contacto/createContacto"
        },
        getRequest: () => {
            return {
                idContacto: selectedId
            }
        },
        saveRequest: (form) => {
            return {
                idContacto: selectedId,
                nombre: form.nombre,
                telefono: form.telefono,
                tipo: form.tipo,
                email: form.email
            }
        }
    }), [selectedId]);

    const actions = [{
        key: "delete",
        name: "Borrar",
        onClick: () => {
            handleDelete();
        }
    }];

    // Handlers
    const handleDelete = () => {
        const rq = {
            idContacto: selectedId
        };

        _removeModal.current.open({
            title: "Eliminando contacto",
            name: form.nombre,
            onYes: () => {
                fetchUrl("/contacto/deleteContacto", "post", rq, {
                    success: (r) => {
                        onSuccess(r.message);
                        onDelete();
                    },
                    error: (r) => {
                        onError(r.errorMessage);
                    }
                }, { block: false });
            }
        });
    }

    return (
        <React.Fragment>
            <RemoveModal
                ref={_removeModal} />

            <SetupTab
                configuration={configuration}
                actions={actions}>
                <Row>
                    <Col md={3}>
                        <FormGroup>
                            <Label required>Nombre</Label>
                            <TextBox
                                name="nombre"
                                placeholder="Nombre" />
                        </FormGroup>
                        <FormGroup style={{ marginTop: "20px" }}>
                            <Label>Telefono</Label>
                            <TextBox
                                name="telefono"
                                placeholder="Telefono" />
                        </FormGroup>
                        <FormGroup style={{ marginTop: "20px" }}>
                            <Label>Email</Label>
                            <TextBox
                                name="email"
                                placeholder="Email" />
                        </FormGroup>
                        <FormGroup style={{ marginTop: "20px" }}>
                            <Label>Tipo de contacto</Label>
                            <TextBox
                                name="tipo"
                                placeholder="Tipo" />
                        </FormGroup>
                    </Col>
                </Row>
            </SetupTab>
        </React.Fragment>
    )
}

export default ContactoInfoTab;