import React from 'react'
import SetupSearch from 'modules/setup/setupSearch/SetupSearch'
import { SearchBox, ClienteCombo, Combo } from 'components'
import { Row, Col } from 'react-bootstrap'

const Search = () => {

	return (
		<SetupSearch>
			<Row>
				<Col md={3}>
					<SearchBox
						name="searchText"
						placeholder="Numero..." />
				</Col>
				<Col md={3}>
					<ClienteCombo
						name="idCliente"
						placeholder="Empresa..." />
				</Col>
				<Col md={3}>
					<Combo
						name="estado"
						placeholder="Estado..."
						items={[
							{ value: "PENDIENTE", label: "PENDIENTE" },
							{ value: "FACTURADO", label: "FACTURADO" },
							{ value: "EN_PROGRESO", label: "EN PROGRESO" },
							{ value: "CANCELADO", label: "CANCELADO" }
						]} />
				</Col>
			</Row>
		</SetupSearch>
	)
}

export default Search;