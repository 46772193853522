export const Home = '/';
export const Nosotros = '/nosotros';
export const Productos = '/productos';
export const Contacto = '/contacto';
export const Servicios = '/servicios';
export const Pedido = '/pedido';
export const Verificar = '/verificar';
export const Cpanel = '/cpanel';
export const Webmail = '/webmail';


export const CRM = 'crm';
export const PotenciaSetup = 'potencia';
export const ProteccionSetup = 'proteccion';
export const CarcasaSetup = 'carcasa';
export const VoltajeSetup = 'voltaje';
export const RotacionSincronicaSetup = 'rotacionSincronica';
export const EmbalajeSetup = 'embalaje';
export const TipoProductoSetup = 'tipoProducto';
export const ProductoSetup = 'productos';
export const PedidoWebSetup = 'pedidosWeb';
export const ConsultaWeb = 'consultasWeb';
export const TrabajoSetup = 'trabajos';
export const ClienteSetup = 'clientes';
export const ContactoSetup = 'contactos';
export const SolicitudDePedidoSetup = 'solicitudesDePedido';
export const PresupuestoSetup = 'presupuestos';
export const PrintPresupuesto = "/printPresupuesto";
export const Login = 'login';