import React, { useMemo, useRef } from 'react'
import SetupTab from 'modules/setup/setupTab/SetupTab'
import { TextBox, TextArea, Label, RemoveModal, ClienteCombo, DatePicker, CheckBox } from 'components'
import { Row, Col, FormGroup } from 'react-bootstrap'
import { fetchUrl } from 'modules/util/functions'
import { useSetupTabValueContext, useSetupTabActionsContext } from 'modules/setup/setupTab/SetupTabContext'
import * as Urls from 'modules/Urls'
import { Link } from 'react-router-dom'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

const TrabajoInfoTab = () => {
	const { selectedId, form, adding } = useSetupTabValueContext();
	const { onDelete, onError, onSuccess } = useSetupTabActionsContext();
	const _removeModal = useRef();

	const configuration = useMemo(() => ({
		urls: {
			get: "/trabajoSetup/getTrabajoInfo",
			save: "/trabajoSetup/saveTrabajoInfo",
			create: "/trabajoSetup/createTrabajo"
		},
		getRequest: () => {
			return {
				idTrabajo: selectedId
			}
		},
		saveRequest: (form) => {
			return {
				idTrabajo: selectedId,
				numero: form.numero,
				idCliente: form.idCliente,
				remitoEmpresa: form.remitoEmpresa,
				fechaRemito: form.fechaRemito,
				potencia: form.potencia,
				marca: form.marca,
				numeroDeFabrica: form.numeroDeFabrica,
				numeroDeEmpresa: form.numeroDeEmpresa,
				polos: form.polos,
				descripcion: form.descripcion,
				monto: form.monto,
				masIva: form.masIva,
				ordenDeCompra: form.ordenDeCompra,
				fechaOrdenDeCompra: form.fechaOrdenDeCompra,
				remitoBal: form.remitoBal,
				fechaRemitoBal: form.fechaRemitoBal,
				facturado: form.facturado,
				numeroDeFactura: form.numeroDeFactura,
				fechaFactura: form.fechaFactura
			}
		}
	}), [selectedId]);

	const actions = [{
		key: "delete",
		name: "Borrar",
		onClick: () => {
			handleDelete();
		}
	},
	{
		key: "cancelar",
		name: "Cancelar",
		icon: faTimes,
		onClick: () => {
			handleCancelar();
		}
	}
	];

	// Handlers
	const handleDelete = () => {
		const rq = {
			idTrabajo: selectedId
		};

		_removeModal.current.open({
			title: "Eliminando trabajo",
			name: form.numero,
			onYes: () => {
				fetchUrl("/trabajoSetup/deleteTrabajo", "post", rq, {
					success: (r) => {
						onSuccess(r.message);
						onDelete();
					},
					error: (r) => {
						onError(r.errorMessage);
					}
				}, { block: false });
			}
		});
	}

	const handleCancelar = () => {
		const rq = {
			idTrabajo: selectedId
		};

		_removeModal.current.open({
			title: "Cancelando trabajo",
			name: form.numero,
			onYes: () => {
				fetchUrl("/trabajoSetup/cancelarTrabajo", "post", rq, {
					success: (r) => {
						onSuccess(r.message);
						onDelete();
					},
					error: (r) => {
						onError(r.errorMessage);
					}
				}, { block: false });
			}
		});
	}

	return (
		<React.Fragment>
			<RemoveModal
				ref={_removeModal} />

			<SetupTab
				configuration={configuration}
				actions={actions}
				renderHeader={() => {
					if (!form.title)
						return;

					return (
						<div style={{ display: "flex" }}>
							<h4>
								{form.title}
								{form.estado &&
									<span> - {form.estado}</span>}
							</h4>
							<hr />
						</div>
					)
				}}>
				<Row>
					<Col md={4}>
						<FormGroup>
							<Label required>Numero</Label>
							<TextBox
								name="numero"
								placeholder="Numero" />
						</FormGroup>
						{!adding &&
							<FormGroup style={{ marginTop: "20px" }}>
								<Label>Presupuesto</Label>
								<div>{form.idPresupuesto ?
									<Link to={`/${Urls.CRM}/${Urls.PresupuestoSetup}/${form.idPresupuesto}`}>{form.numeroPresupuesto}</Link>
									:
									"No tiene presupuesto"
								}</div>
							</FormGroup>}
						{!adding &&
							<FormGroup style={{ marginTop: "20px" }}>
								<Label>Solicitud de pedido</Label>
								<div>{form.idSolicitudPedido ?
									<Link to={`/${Urls.CRM}/${Urls.SolicitudDePedidoSetup}/${form.idSolicitudPedido}`}>{form.numeroSolicitudPedido}</Link>
									:
									"No tiene solicitud de pedido"
								}</div>
							</FormGroup>}
						<FormGroup style={{ marginTop: "20px" }}>
							<Label required>Empresa</Label>
							<ClienteCombo
								name="idCliente"
								placeholder="Empresa" />
						</FormGroup>
						<FormGroup style={{ marginTop: "20px" }}>
							<Label>Remito empresa</Label>
							<TextBox
								name="remitoEmpresa"
								placeholder="Remito empresa" />
						</FormGroup>
						<FormGroup style={{ marginTop: "20px" }}>
							<Label>Fecha de remito</Label>
							<DatePicker
								name="fechaRemito"
								placeholder="Remito empresa" />
						</FormGroup>
						<FormGroup style={{ marginTop: "20px" }}>
							<Label>Potencia</Label>
							<TextBox
								name="potencia"
								placeholder="Potencia" />
						</FormGroup>
						<FormGroup style={{ marginTop: "20px" }}>
							<Label>Marca</Label>
							<TextBox
								name="marca"
								placeholder="Marca" />
						</FormGroup>
					</Col>
					<Col md={4}>
						<FormGroup style={{ marginTop: "20px" }}>
							<Label>Numero de fabrica</Label>
							<TextBox
								name="numeroDeFabrica"
								placeholder="Numero de fabrica" />
						</FormGroup>
						<FormGroup style={{ marginTop: "20px" }}>
							<Label>Numero de empresa</Label>
							<TextBox
								name="numeroDeEmpresa"
								placeholder="Numero de empresa" />
						</FormGroup>
						<FormGroup style={{ marginTop: "20px" }}>
							<Label>Polos</Label>
							<TextBox
								name="polos"
								placeholder="Polos" />
						</FormGroup>
						<FormGroup style={{ marginTop: "20px" }}>
							<Label>Descripcion</Label>
							<TextArea
								name="descripcion"
								placeholder="Descripcion" />
						</FormGroup>
						<Row>
							<Col md={8}>
								<FormGroup style={{ marginTop: "20px" }}>
									<Label>Monto</Label>
									<TextBox
										name="monto"
										placeholder="Monto" />
								</FormGroup>
							</Col>
							<Col md={4}>
								<FormGroup style={{ marginTop: "20px" }}>
									<Label>&nbsp;</Label>
									<TextBox
										name="codigoMoneda"
										placeholder="Moneda"
										disabled />
								</FormGroup>
							</Col>
						</Row>
						<FormGroup style={{ marginTop: "20px" }}>
							<Label>Mas IVA</Label>
							<CheckBox
								name="masIva" />
						</FormGroup>
						<FormGroup style={{ marginTop: "20px" }}>
							<Label>Orden de compra</Label>
							<TextBox
								name="ordenDeCompra"
								placeholder="Orden de compra" />
						</FormGroup>
					</Col>
					<Col md={4}>
						<FormGroup style={{ marginTop: "20px" }}>
							<Label>Fecba orden de compra</Label>
							<DatePicker
								name="fechaOrdenDeCompra"
								placeholder="Fecba orden de compra" />
						</FormGroup>
						<FormGroup style={{ marginTop: "20px" }}>
							<Label>Remito Bal</Label>
							<TextBox
								name="remitoBal"
								placeholder="Remito Bal" />
						</FormGroup>
						<FormGroup style={{ marginTop: "20px" }}>
							<Label>Fecha remito Bal</Label>
							<DatePicker
								name="fechaRemitoBal"
								placeholder="Fecha remito Bal" />
						</FormGroup>
						<FormGroup style={{ marginTop: "20px" }}>
							<Label>Facturado</Label>
							<CheckBox
								name="facturado" />
						</FormGroup>
						<FormGroup style={{ marginTop: "20px" }}>
							<Label>Numero factura</Label>
							<TextBox
								name="numeroDeFactura"
								placeholder="Numero factura" />
						</FormGroup>
						<FormGroup style={{ marginTop: "20px" }}>
							<Label>Fecha factura</Label>
							<DatePicker
								name="fechaFactura"
								placeholder="Fecha factura" />
						</FormGroup>
					</Col>
				</Row>
			</SetupTab>
		</React.Fragment>
	)
}

export default TrabajoInfoTab;