import React, { useMemo, useState } from 'react'
import PublicLayout from 'layout/PublicLayout'
import Home from 'pages/home/Home'
import Nosotros from 'pages/nosotros/Nosotros'
import Contacto from 'pages/contacto/Contacto'
import Servicios from 'pages/servicios/Servicios'
import Pedido from 'pages/pedido/Pedido'
import Productos from 'pages/productos/Productos'
import Verificar from 'pages/verificar/Verificar'
import Producto from '../pages/productos/Producto'
import Dashboard from 'crm/pages/dashboard/Dashboard'
import PotenciaSetup from 'crm/pages/potencia/PotenciaSetup'
import CarcasaSetup from 'crm/pages/carcasa/CarcasaSetup'
import ProteccionSetup from 'crm/pages/proteccion/ProteccionSetup'
import VoltajeSetup from 'crm/pages/voltaje/VoltajeSetup'
import RotacionSincronicaSetup from 'crm/pages/rotacionSincronica/RotacionSincronicaSetup'
import EmbalajeSetup from 'crm/pages/embalaje/EmbalajeSetup'
import TipoProductoSetup from 'crm/pages/tipoProducto/TipoProductoSetup'
import ProductoSetup from 'crm/pages/producto/ProductoSetup'
import InformacionPedido from 'pages/informacionPedido/InformacionPedido'
import PedidoWebSetup from 'crm/pages/pedidoWeb/PedidoWebSetup'
import TrabajoSetup from 'crm/pages/trabajo/TrabajoSetup'
import ClienteSetup from 'crm/pages/cliente/ClienteSetup'
import ContactoSetup from 'crm/pages/contacto/ContactoSetup'
import SolicitudDePedidoSetup from 'crm/pages/solicitudDePedido/SolicitudDePedidoSetup'
import PresupuestoSetup from 'crm/pages/presupuesto/PresupuestoSetup'
import PrintPresupuesto from 'crm/prints/PrintPresupuesto'
import Login from 'pages/login/Login'
import ConsultaWebSetup from 'crm/pages/consultaWeb/ConsultaWebSetup'
import { UserContext } from 'modules/contexts/UserContext'
import { PedidoContext } from 'modules/contexts/PedidoContext'
import { BrowserRouter, Routes as Switch, Route, Navigate } from 'react-router-dom'
import * as BrowserStorage from 'modules/BrowserStorage'
import * as Urls from 'modules/Urls'

const Routes = () => {
	const [user, setUser] = useState(BrowserStorage.get("user"));
	const [pedido, setPedido] = useState(BrowserStorage.get("pedido"));

	const renderSetupRoutes = () => {
		return (
			<>
				<Route path="new" />
				<Route path=":id" />
				<Route path=":id/:tabKey" />
			</>
		)
	}

	const userProviderValue = useMemo(() => ({
		user,
		setUser
	}), [user, setUser]);

	const pedidoProviderValue = useMemo(() => ({
		pedido,
		setPedido
	}), [pedido, setPedido]);

	const ProtectedRoute = ({ element }) => {
		if (!user)
			return <Navigate to={Urls.Home} />;

		return element;
	};

	return (
		<UserContext.Provider value={userProviderValue}>
			<PedidoContext.Provider value={pedidoProviderValue}>
				<BrowserRouter>
					<Switch>

						<Route element={<PublicLayout />}>
							<Route exact path={Urls.Home} element={<Home />} />
							<Route exact path={Urls.Nosotros} element={<Nosotros />} />
							<Route exact path={Urls.Contacto} element={<Contacto />} />
							<Route exact path={Urls.Servicios} element={<Servicios />} />
							<Route exact path={Urls.Productos} element={<Productos />} />
							<Route path={`${Urls.Productos}/:id`} element={<Producto />} />
							<Route exact path={Urls.Login} element={<Login />} />
							<Route exact path={Urls.Pedido} element={<Pedido />} />
							<Route path={`${Urls.Pedido}/:id`} element={<InformacionPedido />} />
							<Route exact path={Urls.Verificar} element={<Verificar />} />

							<Route exact path={Urls.Cpanel} element={<div />} />
							<Route exact path={Urls.Webmail} element={<div />} />

							<Route path="*" element={<Navigate to={Urls.Home} />} />
						</Route>

						<Route path={`${Urls.PrintPresupuesto}/:id`} element={<ProtectedRoute element={<PrintPresupuesto />} />} />


						<Route path={Urls.CRM} element={<ProtectedRoute element={<Dashboard />} />} >
							<Route path={Urls.PotenciaSetup} element={<PotenciaSetup />}>
								{renderSetupRoutes()}
							</Route>

							<Route path={Urls.CarcasaSetup} element={<ProtectedRoute element={<CarcasaSetup />} />}>
								{renderSetupRoutes()}
							</Route>

							<Route path={Urls.ProteccionSetup} element={<ProtectedRoute element={<ProteccionSetup />} />}>
								{renderSetupRoutes()}
							</Route>

							<Route path={Urls.EmbalajeSetup} element={<ProtectedRoute element={<EmbalajeSetup />} />}>
								{renderSetupRoutes()}
							</Route>

							<Route path={Urls.VoltajeSetup} element={<ProtectedRoute element={<VoltajeSetup />} />}>
								{renderSetupRoutes()}
							</Route>

							<Route path={Urls.RotacionSincronicaSetup} element={<ProtectedRoute element={<RotacionSincronicaSetup />} />}>
								{renderSetupRoutes()}
							</Route>

							<Route path={Urls.TipoProductoSetup} element={<ProtectedRoute element={<TipoProductoSetup />} />}>
								{renderSetupRoutes()}
							</Route>

							<Route path={Urls.ProductoSetup} element={<ProtectedRoute element={<ProductoSetup />} />}>
								{renderSetupRoutes()}
							</Route>

							<Route path={Urls.PedidoWebSetup} element={<ProtectedRoute element={<PedidoWebSetup />} />}>
								{renderSetupRoutes()}
							</Route>

							<Route path={Urls.ConsultaWeb} element={<ProtectedRoute element={<ConsultaWebSetup />} />}>
								{renderSetupRoutes()}
							</Route>

							<Route path={Urls.TrabajoSetup} element={<ProtectedRoute element={<TrabajoSetup />} />}>
								{renderSetupRoutes()}
							</Route>

							<Route path={Urls.ClienteSetup} element={<ProtectedRoute element={<ClienteSetup />} />}>
								{renderSetupRoutes()}
							</Route>

							<Route path={Urls.ContactoSetup} element={<ProtectedRoute element={<ContactoSetup />} />}>
								{renderSetupRoutes()}
							</Route>

							<Route path={Urls.SolicitudDePedidoSetup} element={<ProtectedRoute element={<SolicitudDePedidoSetup />} />}>
								{renderSetupRoutes()}
							</Route>

							<Route path={Urls.PresupuestoSetup} element={<ProtectedRoute element={<PresupuestoSetup />} />}>
								{renderSetupRoutes()}
							</Route>

						</Route>

					</Switch>
				</BrowserRouter>
			</PedidoContext.Provider>
		</UserContext.Provider>
	)
}

export default Routes;